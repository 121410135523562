// src/components/AuthTerminal.js
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/AuthTerminalRetro.css';
import api from '../services/api';
import AsciiAnimation from '../components/AsciiAnimation';

const AuthTerminal = ({ onAuthenticated }) => {
  const [terminalState, setTerminalState] = useState('initializing');
  const [terminalLines, setTerminalLines] = useState([]);
  const [authLink, setAuthLink] = useState('');
  const [authCode, setAuthCode] = useState('');
  const [isFlipped, setIsFlipped] = useState(false);
  const navigate = useNavigate();
  const terminalEndRef = useRef(null);
  const pollInterval = useRef(null);
  const [buttonHovered, setButtonHovered] = useState(false);
  const [buttonProgress, setButtonProgress] = useState(0);
  const buttonRef = useRef(null);
  const timeoutsRef = useRef([]);
  const [mapPoints] = useState([
    { x: 30, y: 30, active: true },  // Frankfurt
    { x: 70, y: 40, active: true },  // Singapore
    { x: 20, y: 35, active: true },  // Virginia
    { x: 25, y: 28, active: true },  // London
    { x: 80, y: 35, active: true }   // Tokyo
  ]);

  const addTerminalLine = (text, type = 'info') => {
    const timestamp = new Date().toLocaleTimeString();
    setTerminalLines(prev => [...prev, { text, type, timestamp }]);
  };

  useEffect(() => {
    let mounted = true;
    let timeouts = [];
    
    const initializeTerminal = async () => {
      const initLines = [
        { text: 'Initializing xNod3_Auth_Terminal...', type: 'system', delay: 500 },
        { text: 'Loading security protocols...', type: 'system', delay: 1000 },
        { text: 'Establishing secure connection...', type: 'system', delay: 1500 },
        { text: 'Ready for authentication', type: 'success', delay: 2000 }
      ];

      for (const [index, line] of initLines.entries()) {
        if (!mounted) return;
        const timeout = setTimeout(() => {
          addTerminalLine(line.text, line.type);
          if (index === initLines.length - 1) {
            setTerminalState('ready');
          }
        }, line.delay);
        timeouts.push(timeout);
      }
    };

    initializeTerminal();

    return () => {
      mounted = false;
      timeouts.forEach(clearTimeout);
    };
  }, []);

  const generateAuthLink = async () => {
    try {
      setTerminalState('connecting');
      addTerminalLine('Generating secure authentication link...', 'system');
      addTerminalLine('Initializing Telegram Auth protocol...', 'system');
      
      const response = await api.post('/auth/telegram/generate-link');
      
      if (response.data.authLink && response.data.authCode) {
        const code = response.data.authCode;
        setAuthLink(response.data.authLink);
        setAuthCode(code);
        
        addTerminalLine('Link successfully generated [✓]', 'success');
        addTerminalLine('Awaiting Telegram connection...', 'system');
        addTerminalLine('Click the button below to proceed with authentication', 'info');
        
        setTerminalState('waiting');
        setIsFlipped(true);

        pollInterval.current = setInterval(() => {
          checkAuthStatus(code);
        }, 1000);

        setTimeout(() => {
          if (pollInterval.current) {
            clearInterval(pollInterval.current);
            setTerminalState('error');
            setIsFlipped(false);
            addTerminalLine('Authentication timeout. Please try again [✗]', 'error');
          }
        }, 15 * 60 * 1000);
      }
    } catch (error) {
      console.error('Auth link generation failed:', error);
      addTerminalLine('Failed to generate authentication link [✗]', 'error');
      setTerminalState('error');
    }
  };

  const handleTelegramClick = () => {
    if (!authLink) return;
    
    setTerminalState('connecting');
    addTerminalLine('Initializing secure connection...', 'system');
    
    const sequence = [
      {
        delay: 1000,
        action: () => {
          addTerminalLine('Establishing encrypted channel...', 'system');
          setTerminalState('awaiting');
        }
      },
      {
        delay: 1500,
        action: () => {
          addTerminalLine('Preparing authentication request...', 'system');
        }
      },
      {
        delay: 1000,
        action: () => {
          addTerminalLine('Routing to Telegram secure servers...', 'system');
        }
      },
      {
        delay: 1200,
        action: () => {
          addTerminalLine('Connection established successfully [✓]', 'success');
          setTerminalState('ready_to_auth');
          addTerminalLine('Click the button below to open Telegram authentication', 'info');
        }
      }
    ];

    sequence.reduce((promise, step) => {
      return promise.then(() => {
        return new Promise(resolve => {
          setTimeout(() => {
            step.action();
            resolve();
          }, step.delay);
        });
      });
    }, Promise.resolve());
  };

  const handleOpenTelegram = () => {
    setTerminalState('authenticating');
    window.open(authLink, '_blank');
    addTerminalLine('Telegram authentication page opened [✓]', 'success');
    addTerminalLine('Waiting for authentication completion...', 'system');
    setTerminalState('awaiting');
  };

  const checkAuthStatus = async (code) => {
    try {
      if (!code) return;
      
      const response = await api.post('/auth/telegram/check-status', {
        auth_code: code
      });
      
      if (response.data.status === 'authenticated') {
        clearInterval(pollInterval.current);
        
        addTerminalLine('Confirmation received [✓]', 'success');
        addTerminalLine('Verifying security tokens...', 'system');
        
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('refreshToken', response.data.refreshToken);
        localStorage.setItem('telegram_username', response.data.telegram_username);
        
        addTerminalLine('Tokens successfully stored [✓]', 'success');
        addTerminalLine('Initializing session...', 'system');
        addTerminalLine(`User ${response.data.telegram_username} successfully authenticated [✓]`, 'success');
        
        onAuthenticated?.(response.data.telegram_username);
        
        addTerminalLine('Redirecting to dashboard...', 'system');
        setTimeout(() => navigate('/dashboard'), 1500);
      }
    } catch (error) {
      console.error('Auth status check failed:', error);
      addTerminalLine('Authentication status check failed [✗]', 'error');
    }
  };

  useEffect(() => {
    return () => {
      if (pollInterval.current) {
        clearInterval(pollInterval.current);
      }
    };
  }, []);

  useEffect(() => {
    let progressInterval;
    if (buttonHovered && buttonProgress < 100) {
      progressInterval = setInterval(() => {
        setButtonProgress(prev => Math.min(prev + 2, 100));
      }, 20);
    } else if (!buttonHovered) {
      setButtonProgress(0);
    }
    return () => clearInterval(progressInterval);
  }, [buttonHovered, buttonProgress]);

  const handleButtonClick = async (action) => {
    if (buttonRef.current) {
      buttonRef.current.style.animation = 'none';
      void buttonRef.current.offsetHeight;
      buttonRef.current.style.animation = 'buttonPulse 0.5s ease-out';
    }

    if (action === 'authenticate') {
      await generateAuthLink();
    } else if (action === 'telegram') {
      handleTelegramClick();
    }
  };

  const handleButtonHover = (isHovered) => {
    setButtonHovered(isHovered);
    if (!isHovered) {
      setButtonProgress(0);
    }
  };

  const renderButton = () => {
    if (terminalState === 'ready_to_auth') {
      return (
        <button 
          ref={buttonRef}
          onClick={handleOpenTelegram}
          className="auth-button telegram-auth"
        >
          <div className="auth-button-corner corner-top-left"></div>
          <div className="auth-button-corner corner-top-right"></div>
          <div className="auth-button-corner corner-bottom-left"></div>
          <div className="auth-button-corner corner-bottom-right"></div>
          
          <div className="auth-button-content">
            <span className="auth-button-icon">[📱]</span>
            <span className="auth-button-text"> OPEN_TELEGRAM_AUTH </span>
          </div>
          
          <div className="button-pulse-effect"></div>
        </button>
      );
    }

    if (terminalState === 'waiting' || terminalState === 'connecting' || terminalState === 'awaiting') {
      const buttonText = 
        terminalState === 'connecting' ? '>> ESTABLISHING_CONNECTION <<' :
        terminalState === 'awaiting' ? '>> AWAITING_RESPONSE <<' :
        '>> CONNECT_VIA_TELEGRAM <<';

      const buttonIcon = 
        terminalState === 'connecting' ? '[⚡]' :
        terminalState === 'awaiting' ? '[⌛]' :
        '[🔐]';

      return (
        <button 
          ref={buttonRef}
          onClick={() => handleButtonClick('telegram')}
          className={`auth-button ${terminalState}`}
        >
          <div className="auth-button-corner corner-top-left"></div>
          <div className="auth-button-corner corner-top-right"></div>
          <div className="auth-button-corner corner-bottom-left"></div>
          <div className="auth-button-corner corner-bottom-right"></div>
          
          <div className="auth-button-content">
            <span className="auth-button-icon">{buttonIcon}</span>
            <span className="auth-button-text">{buttonText}</span>
          </div>
          
          <div className="button-glitch-effect"></div>
          <div className="button-scan-effect"></div>
        </button>
      );
    }

    if (terminalState === 'ready') {
      return (
        <button 
          ref={buttonRef}
          onClick={() => handleButtonClick('authenticate')}
          onMouseEnter={() => handleButtonHover(true)}
          onMouseLeave={() => handleButtonHover(false)}
          className="auth-terminal-connect-btn"
          style={{
            '--progress': `${buttonProgress}%`
          }}
        >
          <div className="auth-button-content">
            <span className="auth-button-icon">[🔒]</span>
            <span className="auth-button-text">Authenticate [⏎]</span>
          </div>
          <div className="button-progress-bar" 
               style={{width: `${buttonProgress}%`}} />
        </button>
      );
    }

    return null;
  };

  useEffect(() => {
    return () => {
      timeoutsRef.current.forEach(timeout => clearTimeout(timeout));
      timeoutsRef.current = [];
    };
  }, []);

  useEffect(() => {
    const outputArea = document.querySelector('.auth-terminal-output-area');
    if (outputArea) {
      const isScrolledToBottom = 
        outputArea.scrollHeight - outputArea.clientHeight <= outputArea.scrollTop + 150;
        
      if (isScrolledToBottom) {
        outputArea.scrollTo({
          top: outputArea.scrollHeight,
          behavior: 'smooth'
        });
      }
    }
  }, [terminalLines]);

  return (
    <div className="terminal-container">
      <div className="terminal-animation-area">
        <AsciiAnimation authState={terminalState} />
      </div>
      
      <div className="auth-terminal-wrapper">
        <div className="auth-terminal-box">
          <div className="auth-terminal-header">
            <div className="auth-terminal-controls-group">
              <div className="auth-terminal-control-btn close"></div>
              <div className="auth-terminal-control-btn minimize"></div>
              <div className="auth-terminal-control-btn maximize"></div>
            </div>
            <div className="auth-terminal-title-text">xNod3_Auth_Terminal [v2.0]</div>
            <div className={`auth-terminal-status-indicator ${terminalState}`}>
              {terminalState.toUpperCase()}
            </div>
          </div>

          <div className="auth-terminal-content">
            <div className="auth-terminal-output-area">
              {terminalLines.map((line, index) => (
                <div key={index} className={`auth-terminal-line-item ${line.type}`}>
                  <span className="auth-terminal-prefix">{'>>'}</span>
                  <span className="auth-terminal-text">{line.text}</span>
                  <span className="auth-terminal-timestamp">[{line.timestamp}]</span>
                </div>
              ))}
            </div>

            <div className="auth-terminal-input-area">
              <div className={`auth-button-container ${isFlipped ? 'flipped' : ''}`}>
                {renderButton()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthTerminal;
