import React, { useState, useEffect, useRef } from 'react';
import '../styles/AboutRetro.css';
import BackgroundMatrix from '../components/BackgroundMatrix';

const AboutPage = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [textIndex, setTextIndex] = useState(0);
  const [activeTeamMember, setActiveTeamMember] = useState(null);
  const headerRef = useRef(null);
  
  const highlightedWords = {
    'hidden knowledge': 'Ancient wisdom transformed into modern security',
    'secure connections': 'Encrypted pathways to the future',
    'privacy': 'A core value of our philosophy',
    'decentralized frontier': 'The new digital landscape'
  };

  const missionPoints = [
    {
      id: 'security',
      icon: '🛡️',
      title: 'Security First',
      description: 'Implementing military-grade encryption and security protocols'
    },
    {
      id: 'innovation',
      icon: '⚡',
      title: 'Continuous Innovation',
      description: 'Pushing the boundaries of blockchain technology'
    },
    {
      id: 'reliability',
      icon: '🔄',
      title: '24/7 Reliability',
      description: 'Maintaining 99.99% uptime for all nodes'
    }
  ];

  const team = [
    { 
      id: 'grisha',
      symbol: '[◎]',
      codeName: 'grisha_nodes',
      role: 'Lead Technical Specialist',
      status: 'ONLINE',
      description: 'grisha_nodes is the backbone of xNod3\'s technical infrastructure, the keeper of node stability, and the go-to for support. With over a decade of experience in distributed systems and blockchain technology, he ensures our infrastructure remains cutting-edge and secure.',
      command: './run support --grisha_nodes',
      achievements: ['Node Master', 'Security Expert', 'Performance Guru'],
      specialties: ['Infrastructure Design', 'Security Auditing', 'Performance Optimization'],
      stats: {
        nodesManaged: '1000+',
        uptime: '99.99%',
        issuesResolved: '2.5k+'
      }
    },
    { 
      id: 'fred',
      symbol: '[◎]',
      codeName: 'fred',
      role: 'Co-Founder & Visionary',
      status: 'ACTIVE',
      description: 'fred is the visionary behind xNod3, blending ancient principles with cutting-edge technology to create a truly unique ecosystem. His innovative approach to blockchain infrastructure has revolutionized how we think about node deployment and management.',
      command: './initiate mission --fred',
      achievements: ['Blockchain Pioneer', 'System Architect', 'Innovation Leader'],
      specialties: ['Strategic Planning', 'System Architecture', 'Blockchain Innovation'],
      stats: {
        projectsLed: '50+',
        innovations: '15+',
        yearsExperience: '8+'
      }
    }
  ];

  useEffect(() => {
    setIsVisible(true);
    const timer = setInterval(() => {
      setTextIndex(prev => prev < 3 ? prev + 1 : prev);
    }, 800);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const header = headerRef.current;
    if (!header) return;

    const handleMouseMove = (e) => {
      const { left, top, width, height } = header.getBoundingClientRect();
      const x = (e.clientX - left) / width;
      const y = (e.clientY - top) / height;
      
      header.style.setProperty('--x', `${x * 100}%`);
      header.style.setProperty('--y', `${y * 100}%`);
    };

    header.addEventListener('mousemove', handleMouseMove);
    return () => header.removeEventListener('mousemove', handleMouseMove);
  }, []);

  return (
    <div className="xnabout-container">
      <BackgroundMatrix />
      <div className="scan-effect" />
      
      <div className={`xnabout-content ${isVisible ? 'xnabout-section' : ''}`}>
        <div className="xnabout-header" ref={headerRef}>
          <div className="xnabout-header-matrix" />
          <h1 className="xnabout-title">About xNod3<span className="cursor">_</span></h1>
          <p className="xnabout-subtitle">The minds behind xNod3 - a cryptic alliance of blockchain experts</p>
        </div>

        <div className="xnabout-text-section">
          <p className={`xnabout-text ${textIndex >= 0 ? 'visible' : ''}`}>
            xNod3 emerged from the shadows of the blockchain realm, founded by visionaries who saw the need for a more secure and private approach to node deployment.
          </p>
          
          <p className={`xnabout-text ${textIndex >= 1 ? 'visible' : ''}`}>
            Our mission is to provide enterprise-grade node infrastructure while maintaining the highest standards of <span className="highlight" title={highlightedWords.privacy}>privacy</span> and security.
          </p>
          
          <p className={`xnabout-text ${textIndex >= 2 ? 'visible' : ''}`}>
            We operate at the intersection of <span className="highlight" title={highlightedWords['hidden knowledge']}>hidden knowledge</span> and modern technology, creating <span className="highlight" title={highlightedWords['secure connections']}>secure connections</span> in the <span className="highlight" title={highlightedWords['decentralized frontier']}>decentralized frontier</span>.
          </p>
        </div>

        <div className="xnabout-mission-section">
          <h2 className="xnabout-section-title">Our Mission<span className="cursor">_</span></h2>
          <div className="xnabout-mission-points">
            {missionPoints.map(point => (
              <div key={point.id} className="xnabout-mission-point">
                <span className="xnabout-mission-icon">{point.icon}</span>
                <h3 className="xnabout-mission-title">{point.title}</h3>
                <p className="xnabout-mission-description">{point.description}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="xnabout-team-section">
          <h2 className="xnabout-team-title">The Team<span className="cursor">_</span></h2>
          <div className="xnabout-team-list">
            {team.map(member => (
              <div 
                key={member.id} 
                className="xnabout-team-member"
                onMouseEnter={() => setActiveTeamMember(member.id)}
                onMouseLeave={() => setActiveTeamMember(null)}
              >
                <div className="xnabout-member-status">{member.status}</div>
                <div className="xnabout-member-header">
                  <span className="xnabout-member-symbol">{member.symbol}</span>
                  <div className="xnabout-member-info">
                    <span className="xnabout-member-name">{member.codeName}</span>
                    <span className="xnabout-member-role">{member.role}</span>
                  </div>
                </div>
                <p className="xnabout-member-description">{member.description}</p>
                
                <div className="xnabout-member-specialties">
                  <h4>Specialties:</h4>
                  <ul>
                    {member.specialties.map(specialty => (
                      <li key={specialty}>{specialty}</li>
                    ))}
                  </ul>
                </div>

                <div className="xnabout-member-stats">
                  {Object.entries(member.stats).map(([key, value]) => (
                    <div key={key} className="xnabout-stat-item">
                      <span className="xnabout-stat-value">{value}</span>
                      <span className="xnabout-stat-label">{key.replace(/([A-Z])/g, ' $1').toLowerCase()}</span>
                    </div>
                  ))}
                </div>

                <div className="xnabout-member-achievements">
                  {member.achievements.map(achievement => (
                    <span key={achievement} className="xnabout-achievement-badge">
                      {achievement}
                    </span>
                  ))}
                </div>

                <div className="xnabout-member-command">
                  <span className="xnabout-command-prompt">$</span>
                  <span className="xnabout-command-text">{member.command}</span>
                  {activeTeamMember === member.id && <span className="xnabout-command-cursor" />}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="xnabout-footer">
          <h2 className="xnabout-section-title">Join Our Mission<span className="cursor">_</span></h2>
          <p className="xnabout-footer-text">
            We're always looking for talented individuals who share our vision of a more secure and decentralized future. 
            If you're passionate about blockchain technology and want to be part of something revolutionary, we want to hear from you.
          </p>
          <div className="xnabout-contact-buttons">
            <button className="xnabout-contact-btn primary">Join the Team</button>
            <button className="xnabout-contact-btn secondary">Contact Us</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage; 