import React, { useEffect, useState, useCallback } from 'react';
import '../styles/AsciiAnimation.css';

const AsciiAnimation = ({ authState = 'initializing' }) => {
  const [animationFrame, setAnimationFrame] = useState(0);
  const [fadeState, setFadeState] = useState('in');

  // Синхронизируем с состояниями из AuthTerminal
  const stateConfigs = {
    initializing: {
      text: 'INITIALIZING',
      symbol: '◈',
      progressChars: '▒░░░░░░░░░',
      subtext: '[SYS:0x01] LOADING',
      securityLevel: 'LOW'
    },
    ready: {
      text: 'SYSTEM READY',
      symbol: '■',
      progressChars: '░░░░░░░░░░',
      subtext: '[SYS:0x02] STANDBY',
      securityLevel: 'MEDIUM'
    },
    connecting: {
      text: 'CONNECTING',
      symbol: '◇',
      progressChars: '█▓▒░░░░░░░',
      subtext: 'ESTABLISHING LINK'
    },
    waiting: {
      text: 'AWAITING',
      symbol: '◆',
      progressChars: '██████░░░░',
      subtext: 'WAITING FOR RESPONSE'
    },
    awaiting: {
      text: 'PROCESSING',
      symbol: '◆',
      progressChars: '████████░░',
      subtext: 'VERIFYING ACCESS'
    },
    success: {
      text: 'ACCESS GRANTED',
      symbol: '★',
      progressChars: '██████████',
      subtext: 'WELCOME TO xNOD3'
    },
    error: {
      text: 'ACCESS DENIED',
      symbol: '×',
      progressChars: '█░█░█░█░█░',
      subtext: 'SECURITY VIOLATION'
    },
    ready_to_auth: {
      text: 'READY TO AUTH',
      symbol: '◆',
      progressChars: '████████░░',
      subtext: 'TELEGRAM READY'
    }
  };

  const generateMatrix = useCallback(() => {
    const chars = '01'.split('');
    return Array(5).fill().map(() => 
      Array(20).fill().map(() => chars[Math.floor(Math.random() * chars.length)]).join('')
    );
  }, []);

  const [matrix, setMatrix] = useState(generateMatrix());

  useEffect(() => {
    const interval = setInterval(() => {
      setMatrix(generateMatrix());
    }, 1000);

    return () => clearInterval(interval);
  }, [generateMatrix]);

  const generateFrame = useCallback((state) => {
    const config = stateConfigs[state] || stateConfigs.initializing;
    const matrix = generateMatrix();
    
    const width = 35;
    const line = "=".repeat(width-6);
    
    // Изменяем угловые декораторы на треугольники
    const corner = "▲";
    const cornerBottom = "▼";
    
    const centerText = (text, length) => {
      const spaces = Math.max(0, length - text.length);
      const leftSpace = Math.floor(spaces / 2);
      const rightSpace = spaces - leftSpace;
      return " ".repeat(leftSpace) + text + " ".repeat(rightSpace);
    };
    
    // Добавляем системное время (без HTML-тегов)
    const time = new Date().toLocaleTimeString('en-US', { hour12: false });
    const sysInfo = `SYS:${time}`;
    
    // Добавляем индикаторы статуса
    const statusIndicator = (active) => active ? "□" : "□";
    const statusLine = Array(3).fill().map((_, i) => 
      statusIndicator(i < (config.progressChars.match(/█/g) || []).length / 3)
    ).join(" ");

    // Вычисляем длину декоративной границы
    const decorLength = Math.max(0, Math.floor((width - 6 - config.text.length - 2) / 2));
    const decorBorder = "·".repeat(decorLength);
    
    // Правильное выравнивание для SECURITY SYSTEM
    const securityText = `${corner} SECURITY SYSTEM ${cornerBottom}`;
    const securityLine = centerText(securityText, width - 6);
    
    return `|| ${line} ||
|| ${matrix[0].padEnd(width - 6)} ||
|| ${securityLine} ||
|| ${matrix[1].padEnd(width - 6)} ||
|| ${decorBorder} ${config.text} ${decorBorder} ||
|| ${centerText(`[${config.progressChars}]`, width - 6)} ||
|| ${centerText(statusLine, width - 6)} ||
|| ${centerText(sysInfo, width - 6)} ||
|| ${centerText(config.subtext, width - 6)} ||
|| ${matrix[2].padEnd(width - 6)} ||
|| ${line} ||`;
  }, [generateMatrix, stateConfigs]);

  useEffect(() => {
    setFadeState('out');
    setTimeout(() => {
      setAnimationFrame(prev => prev + 1);
      setFadeState('in');
    }, 300);
  }, [authState]);

  return (
    <div className={`ascii-animation state-${authState}`}>
      <div className="animation-content">
        <div className={`frame-container ${fadeState}`}>
          <pre className="ascii-art">{generateFrame(authState)}</pre>
        </div>
        <div className="status-indicators">
          <div className="status-dot"></div>
          <div className="status-dot"></div>
          <div className="status-dot"></div>
        </div>
      </div>
    </div>
  );
};

export default AsciiAnimation; 