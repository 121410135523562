import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import CodeBlock from '../components/CodeBlock';
import '../styles/GuidePage.css';
import api from '../services/api';
import remarkGfm from 'remark-gfm';

const HighlightText = ({ children }) => {
  return <span className="highlight-text">{children}</span>;
};

function GuidePage() {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [guide, setGuide] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [readingProgress, setReadingProgress] = useState(0);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [modalImage, setModalImage] = useState(null);
  const [isClosing, setIsClosing] = useState(false);
  const [relatedGuides, setRelatedGuides] = useState([]);

  useEffect(() => {
    const fetchGuide = async () => {
      try {
        if (!slug) {
          setError('Гайд не найден');
          setTimeout(() => navigate('/guides'), 3000);
          return;
        }

        if (slug.startsWith('private-guide-')) {
          setError('ACCESS_RESTRICTED');
          return;
        }

        const response = await api.get(`/guides/${slug}`);
        if (response.data) {
          setGuide(response.data);
        } else {
          throw new Error('Guide not found');
        }
      } catch (err) {
        console.error('Error fetching guide:', err);
        if (err.response?.data?.error === 'ACCESS_RESTRICTED') {
          setGuide(err.response.data.guide);
          setError('ACCESS_RESTRICTED');
        } else {
          setError('Гайд не найден');
          setTimeout(() => navigate('/guides'), 3000);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchGuide();
  }, [slug, navigate]);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollTop = window.scrollY;
      const progress = (scrollTop / (documentHeight - windowHeight)) * 100;
      
      document.documentElement.style.setProperty(
        '--progress-width',
        `${Math.min(progress, 100)}%`
      );
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const checkSubscription = async () => {
      try {
        const token = localStorage.getItem('token');
        if (token) {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/auth/me`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          setIsSubscribed(response.data.user.isPremium);
        }
      } catch (err) {
        console.error('Error checking subscription:', err);
      }
    };
    
    checkSubscription();
  }, []);

  const handleImageClick = (src) => {
    setModalImage(src);
  };

  const closeModal = () => {
    setIsClosing(true);
    setTimeout(() => {
      setModalImage(null);
      setIsClosing(false);
    }, 200);
  };

  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape' && modalImage) {
        closeModal();
      }
    };

    window.addEventListener('keydown', handleEscKey);
    return () => window.removeEventListener('keydown', handleEscKey);
  }, [modalImage]);

  useEffect(() => {
    const fetchRelatedGuides = async () => {
      if (guide?.category && guide?.tags) {
        try {
          const response = await api.get('/guides/related', {
            params: {
              category: guide.category,
              tags: guide.tags,
              currentGuideId: guide.id,
              limit: 3
            }
          });
          setRelatedGuides(response.data);
        } catch (err) {
          console.error('Error fetching related guides:', err);
        }
      }
    };

    if (guide) {
      fetchRelatedGuides();
    }
  }, [guide]);

  const renderRestrictedAccess = () => {
    return (
      <div className="guide-access-restricted">
        <div className="restricted-container">
          <div className="restricted-header">
            <span className="warning-icon">[⚠️]</span>
            <h2 className="restricted-title">Restricted Access<span className="blink">_</span></h2>
          </div>

          <div className="restricted-content">
            <p className="restricted-message">
              This guide is part of the <span className="highlight">xNod3 Private Access</span> program.
              <br />
              Unlock exclusive guides, tools, and resources with a <span className="highlight">subscription</span>.
            </p>

            <div className="price-block">
              <div className="price-tag">
                <span className="price-icon">[💵]</span>
                <span className="price-amount">$27</span>
                <span className="price-period">/month</span>
              </div>
              <p className="price-note">* Cancel anytime, no hidden fees</p>
            </div>

            <Link to="/dashboard" className="access-button">
              <span className="button-icon">[⚙️]</span>
              <span className="button-text">Unlock Access in Dashboard</span>
            </Link>
          </div>
        </div>
      </div>
    );
  };

  const renderLoadingState = () => (
    <div className="guide-loading">
      <div className="loading-container">
        <h2 className="loading-title">Loading Guide</h2>
        <div className="loading-spinner"></div>
        <div className="loading-progress"></div>
        <div className="loading-status">
          <div className="loading-status-line">
            <span className="status-icon">[⟳]</span>
            <span>Fetching guide data...</span>
          </div>
          <div className="loading-status-line">
            <span className="status-icon">[⟲]</span>
            <span>Processing content...</span>
          </div>
        </div>
      </div>

      <div className="loading-placeholder">
        <div className="placeholder-header">
          <div className="placeholder-breadcrumbs">
            <div className="placeholder-line breadcrumb"></div>
          </div>
          <div className="placeholder-title-section">
            <div className="placeholder-line title-main"></div>
            <div className="placeholder-meta">
              <div className="placeholder-line category"></div>
              <div className="placeholder-line tag"></div>
              <div className="placeholder-line tag"></div>
            </div>
          </div>
        </div>

        <div className="placeholder-content">
          <div className="placeholder-section">
            <div className="placeholder-line section-title"></div>
            <div className="placeholder-line text long"></div>
            <div className="placeholder-line text medium"></div>
            <div className="placeholder-line text long"></div>
          </div>

          <div className="placeholder-section">
            <div className="placeholder-line section-title"></div>
            <div className="placeholder-code-block">
              <div className="placeholder-line code"></div>
              <div className="placeholder-line code"></div>
              <div className="placeholder-line code"></div>
            </div>
          </div>

          <div className="placeholder-section">
            <div className="placeholder-line section-title"></div>
            <div className="placeholder-line text medium"></div>
            <div className="placeholder-line text long"></div>
            <div className="placeholder-line text short"></div>
          </div>
        </div>
      </div>
    </div>
  );

  if (loading) return renderLoadingState();
  if (error === 'ACCESS_RESTRICTED' || (!guide?.is_public && !isSubscribed)) {
    return renderRestrictedAccess();
  }
  if (error) return <div className="guide-error">[!] {error}</div>;
  if (!guide) return null;

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "TechnicalArticle",
    "headline": guide.title,
    "description": guide.description,
    "image": guide.headerImage,
    "datePublished": guide.created_at,
    "dateModified": guide.updated_at,
    "author": {
      "@type": "Organization",
      "name": "xNod3"
    },
    "publisher": {
      "@type": "Organization",
      "name": "xNod3",
      "logo": {
        "@type": "ImageObject",
        "url": "/logo.png"
      }
    },
    "keywords": guide.tags?.join(", ")
  };

  return (
    <div className="guide-page">
      <div className="reading-progress">
        <div className="reading-progress-bar" />
      </div>
      <Helmet>
        <title>{`${guide.title} - xNod3 Guides`}</title>
        <meta name="description" content={guide.description} />
        
        <meta property="og:title" content={guide.title} />
        <meta property="og:description" content={guide.description} />
        <meta property="og:type" content="article" />
        <meta property="og:image" content={guide.headerImage} />
        
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={guide.title} />
        <meta name="twitter:description" content={guide.description} />
        <meta name="twitter:image" content={guide.headerImage} />
        
        <meta name="author" content="xNod3" />
        <meta name="keywords" content={guide.tags?.join(", ")} />
        
        {/* Для приватных гайдов указываем canonical на основную страницу и запрещаем индексацию */}
        {slug.startsWith('private-guide-') ? (
          <>
            <link rel="canonical" href={`${window.location.origin}/guides`} />
            <meta name="robots" content="noindex, nofollow" />
          </>
        ) : (
          <link rel="canonical" href={`${window.location.origin}/guides/${slug}`} />
        )}
        
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      <div className="guide-container">
        <Link to="/guides" className="back-button">
          <span className="back-icon">[⮌]</span>
          Back to Guides
        </Link>
        
        <div className="breadcrumbs">
          <Link to="/" className="breadcrumb-item">
            <span className="breadcrumb-icon">[⌂]</span>
            <span className="breadcrumb-text">Home</span>
          </Link>
          <span className="breadcrumb-separator">/</span>
          <Link to="/guides" className="breadcrumb-item">
            <span className="breadcrumb-icon">[◈]</span>
            <span className="breadcrumb-text">Guides</span>
          </Link>
          <span className="breadcrumb-separator">/</span>
          <span className="breadcrumb-item current">
            <span className="breadcrumb-icon">[⚡]</span>
            <span className="breadcrumb-text">{guide.title}</span>
          </span>
        </div>
        
        {guide.headerImage && (
          <div className="guide-header-image">
            <div className="header-image" style={{ backgroundImage: `url(${guide.headerImage})` }}>
              <div className="header-overlay"></div>
            </div>
          </div>
        )}

        <div className="guide-header">
          <div className="guide-title-section">
            <div className="guide-title-wrapper">
              <span className="guide-icon rotating-icon" data-animate="true">[⚙️]</span>
              <h1 className="guide-title typing-effect">{guide.title}</h1>
            </div>
            
            <div className="guide-meta-line">
              <span className="guide-category-badge glow-effect">[{guide.category}]</span>
              {guide.tags && guide.tags.length > 0 && (
                <div className="guide-tags">
                  {guide.tags.map(tag => (
                    <span key={tag} className="guide-tag">[#] {tag}</span>
                  ))}
                </div>
              )}
            </div>
            
            <span className="guide-date">
              <span className="time-icon">[🕒]</span>
              Last Updated: {new Date(guide.updated_at).toLocaleDateString()}
            </span>
            
            <div className="guide-action-button">
              <button className="install-button">
                <span className="button-icon">[⚡]</span>
        {guide.description}
              </button>
            </div>
          </div>
        </div>

        {guide.projectInfo && (
          <div className="guide-project-info">
            <div className="project-info-header">
              <span className="project-info-icon">[⟁]</span>
              <h3 className="project-info-title">Project Information_</h3>
            </div>
            
            <div className="project-info-content">
              <div className="project-info-logo">
                {guide.projectInfo.logo_url ? (
                  <img src={guide.projectInfo.logo_url} alt={guide.projectInfo.name} />
                ) : (
                  <div className="project-placeholder-logo">[N]</div>
                )}
              </div>
              
              <div className="project-info-details">
                <div className="project-info-name">
                  <span className="detail-label">&gt; Name:</span>
                  <span className="detail-value">{guide.projectInfo.name}</span>
                </div>
                
                <div className="project-info-version">
                  <span className="detail-label">&gt; Version:</span>
                  <span className="detail-value">{guide.projectInfo.node_version || 'N/A'}</span>
                </div>
                
                <div className="project-info-status">
                  <span className="detail-label">&gt; Status:</span>
                  <span className={`detail-value status-${guide.projectInfo.status}`}>
                    {guide.projectInfo.status === 'active' ? '[✓] Active' : '[◯] Inactive'}
                  </span>
                </div>
                
                <div className="project-info-network">
                  <span className="detail-label">&gt; Network:</span>
                  <span className={`detail-value network-${guide.projectInfo.testnet_status}`}>
                    {guide.projectInfo.testnet_status === 'active' ? '[T] Testnet' : '[M] Mainnet'}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="guide-content-wrapper">
          <div className="content-header">
            <span className="content-title">&gt; CONTENT</span>
          </div>
          <div className="content-body">
            <ReactMarkdown 
              remarkPlugins={[remarkGfm]}
              components={{
                code: ({node, inline, className, children, ...props}) => {
                  if (inline) {
                    return <code className={className} {...props}>{children}</code>;
                  }
                  return (
                    <CodeBlock className={className}>
                      {String(children).replace(/\n$/, '')}
                    </CodeBlock>
                  );
                },
                p: ({node, children}) => {
                  const text = String(children);
                  if (text.match(/^\[(.+?)\]\s+.*_$/)) {
                    const title = text.replace(/^\[(.+?)\]\s+/, '').replace(/_$/, '');
                    const icon = text.match(/^\[(.+?)\]/)[1];
                    return (
                      <h2 className="guide-section-header">
                        <span className="section-icon">{icon}</span>
                        {title}
                      </h2>
                    );
                  }
                  return <p>{children}</p>;
                },
                img: ({node, ...props}) => (
                  <div className="image-container">
                    <div className="image-header">
                      <div className="window-controls">
                        <span className="control close"></span>
                        <span className="control minimize"></span>
                        <span className="control maximize"></span>
                      </div>
                    </div>
                    <img {...props} onClick={() => handleImageClick(props.src)} />
                  </div>
                ),
                a: ({node, children, href, ...props}) => (
                  <a href={href} target="_blank" rel="noopener noreferrer" {...props}>
                    {children}
                  </a>
                ),
                del: ({node, children}) => {
                  if (String(children).startsWith('=') && String(children).endsWith('=')) {
                    const highlightedText = String(children).slice(1, -1);
                    return <span className="highlight-text">{highlightedText}</span>;
                  }
                  return <del>{children}</del>;
                },
                text: ({node, children}) => {
                  const text = String(children);
                  const matches = text.match(/==(.*?)==/g);
                  
                  if (!matches) return children;
                  
                  let result = text;
                  matches.forEach(match => {
                    const content = match.slice(2, -2);
                    result = result.replace(match, `<span class="highlight-text">${content}</span>`);
                  });
                  
                  return <span dangerouslySetInnerHTML={{ __html: result }} />;
                },
                em: ({node, children}) => {
                  const text = String(children);
                  if (text.startsWith('=') && text.endsWith('=')) {
                    return <HighlightText>{text.slice(1, -1)}</HighlightText>;
                  }
                  return <em>{children}</em>;
                },
              }}
            >
              {guide.content}
            </ReactMarkdown>
          </div>
        </div>
      </div>

      {relatedGuides.length > 0 && (
        <section className="related-guides">
          <div className="related-guides-header">
            <h2 className="related-guides-title">Похожие гайды</h2>
          </div>
          <div className="related-guides-grid">
            {relatedGuides.map(relatedGuide => (
              <article
                key={relatedGuide.id}
                className={`related-guide-card ${!relatedGuide.is_public ? 'masked' : ''}`}
                onClick={() => {
                  navigate(`/guides/${relatedGuide.slug}`);
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
              >
                <h3 className="related-guide-title">{relatedGuide.title}</h3>
                <p className="related-guide-description">{relatedGuide.description}</p>
                <div className="related-guide-meta">
                  <span className="related-guide-category">{relatedGuide.category}</span>
                </div>
              </article>
            ))}
          </div>
        </section>
      )}

      {modalImage && (
        <div className={`guide-image-modal ${isClosing ? 'closing' : ''}`} onClick={closeModal}>
          <div className="guide-modal-content">
            <button className="guide-modal-close" onClick={closeModal}>×</button>
            <img 
              src={modalImage} 
              alt="Full size" 
              onClick={closeModal}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default GuidePage;
