import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLanguage } from '../contexts/LanguageContext';
import { translations } from '../translations/privateAccess';
import LanguageToggle from '../components/LanguageToggle';
import '../styles/PrivateAccess.css';
import { Link, useNavigate } from 'react-router-dom';
import { useSubscriptionPrice } from '../hooks/useSubscriptionPrice';

const menuItems = [
  {
    icon: '⬤',
    label: 'Stop node',
    description: 'Stop node process'
  },
  {
    icon: '↻',
    label: 'Restart node',
    description: 'Restart current node'
  },
  {
    icon: '📄',
    label: 'Show logs',
    description: 'View node logs'
  },
  {
    icon: '⚒',
    label: 'Worker management',
    description: 'Add/remove workers'
  },
  {
    icon: '✕',
    label: 'Remove node',
    description: 'Remove node completely'
  },
  {
    icon: '🖳',
    label: 'System info',
    description: 'Show system info'
  },
  {
    icon: '⮐',
    label: 'Exit',
    description: 'Exit program'
  }
];

function PrivateAccess() {
  const { language } = useLanguage();
  const t = translations[language] || translations['en'];
  const { price, loading } = useSubscriptionPrice();
  
  const [showScriptModal, setShowScriptModal] = useState(false);
  const [copied, setCopied] = useState(false);
  const [currentLogIndex, setCurrentLogIndex] = useState(0);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [currentUpdateIndex, setCurrentUpdateIndex] = useState(0);
  const [notificationsEnabled, setNotificationsEnabled] = useState(false);
  const [expandedFaq, setExpandedFaq] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState('monthly');
  const [isUpdating, setIsUpdating] = useState(false);
  const [priceKey, setPriceKey] = useState(0);
  const [selectedMenuItem, setSelectedMenuItem] = useState(0);
  const [nodeStatus, setNodeStatus] = useState('running');
  const [showNodeMenu, setShowNodeMenu] = useState(false);
  const [logs, setLogs] = useState([]);
  const [showLogs, setShowLogs] = useState(false);
  const [workerCount, setWorkerCount] = useState(2);
  const [systemInfo, setSystemInfo] = useState(null);
  const [showSyncManager, setShowSyncManager] = useState(false);
  const [showAnalytics, setShowAnalytics] = useState(false);
  const [syncStatus, setSyncStatus] = useState({
    progress: 0,
    currentBlock: 0,
    totalBlocks: 8_450_000,
    speed: '1.2M blocks/hour',
    eta: '2 hours 15 minutes'
  });
  const [analyticsData, setAnalyticsData] = useState({
    cpu: {
      usage: 45,
      temperature: 58,
      threads: 16
    },
    memory: {
      used: 28.4,
      total: 64,
      swap: 2.1
    },
    network: {
      incoming: 125.4,
      outgoing: 89.7,
      peers: 147
    },
    storage: {
      used: 856,
      total: 2000,
      readSpeed: 524,
      writeSpeed: 486
    }
  });
  const [messages, setMessages] = useState([
    {
      id: 1,
      type: 'system',
      content: '=== Welcome to xNod3 Community Chat ==='
    },
    {
      id: 2,
      type: 'message',
      author: 'CryptoMaster',
      avatar: '👨‍💻',
      content: 'Just installed my first node, sync speed is amazing! Got from 0 to 8M blocks in under 4 hours.',
      timestamp: '2 hours ago'
    },
    {
      id: 3,
      type: 'message',
      author: 'xNod3_Admin',
      avatar: '⚡',
      isAdmin: true,
      content: 'Glad to hear that! The new parallel sync engine in v2.1.4 really makes a difference.',
      timestamp: '2 hours ago'
    },
    {
      id: 4,
      type: 'message',
      author: 'NodeRunner',
      avatar: '🔧',
      content: 'Anyone else seeing improved performance after switching to 4 workers per core?',
      timestamp: '1 hour ago'
    },
    {
      id: 5,
      type: 'message',
      author: 'xNod3_Support',
      avatar: '🛡️',
      isAdmin: true,
      content: 'Yes! For most setups, 4 workers/core is optimal. Just keep an eye on memory usage.',
      timestamp: '1 hour ago'
    },
    {
      id: 6,
      type: 'message',
      author: 'Blockchain_Eve',
      avatar: '🌐',
      content: 'New to xNod3 here! Any tips for optimizing network settings?',
      timestamp: '30 minutes ago'
    },
    {
      id: 7,
      type: 'message',
      author: 'TechGuru',
      avatar: '🎮',
      content: 'Check out the Performance Analytics tool, it helped me a lot with network tuning',
      timestamp: '25 minutes ago'
    }
  ]);
  const [inputMessage, setInputMessage] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const chatRef = useRef(null);
  const [showSubscriptionAlert, setShowSubscriptionAlert] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState(0);
  const [features, setFeatures] = useState([
    {
      id: 'setup',
      icon: '[✓]',
      title: 'Node Setup Tutorials',
      description: 'Step-by-step guides for node installation and configuration',
      examples: [
        { name: 'ritual_node_setup.md', size: '245KB' },
        { name: 'quick_start_guide.md', size: '180KB' },
        { name: 'requirements_check.md', size: '156KB' }
      ]
    },
    {
      id: 'performance',
      icon: '[⚡]',
      title: 'Performance Optimization',
      description: 'Advanced techniques for maximizing node performance',
      examples: [
        { name: 'performance_tuning.md', size: '312KB' },
        { name: 'resource_management.md', size: '289KB' },
        { name: 'network_optimization.md', size: '234KB' }
      ]
    },
    {
      id: 'security',
      icon: '[🛡️]',
      title: 'Security Guidelines',
      description: 'Best practices for securing your node infrastructure',
      examples: [
        { name: 'security_checklist.md', size: '276KB' },
        { name: 'access_control.md', size: '198KB' },
        { name: 'threat_prevention.md', size: '245KB' }
      ]
    },
    {
      id: 'troubleshooting',
      icon: '[🔧]',
      title: 'Troubleshooting Manuals',
      description: 'Common issues and their solutions',
      examples: [
        { name: 'error_handling.md', size: '289KB' },
        { name: 'diagnostic_tools.md', size: '234KB' },
        { name: 'recovery_guide.md', size: '267KB' }
      ]
    }
  ]);

  const intervalRef = useRef(null);
  const [isChanging, setIsChanging] = useState(false);
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);

  useEffect(() => {
    setCurrentLogIndex(0);
    
    const timer = setInterval(() => {
      setCurrentLogIndex(prev => {
        if (prev < t.installationLogs.length) {
          return prev + 1;
        }
        clearInterval(timer);
        return prev;
      });
    }, 500);

    return () => clearInterval(timer);
  }, [t.installationLogs.length]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (currentMessageIndex < t.supportMessages.length) {
        setCurrentMessageIndex(prev => prev + 1);
      } else {
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [currentMessageIndex, t.supportMessages.length]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'ArrowLeft') {
        handlePlanChange(
          selectedPlan === 'twelveMonths' ? 'sixMonths' : 
          selectedPlan === 'sixMonths' ? 'monthly' : 
          selectedPlan
        );
      } else if (e.key === 'ArrowRight') {
        handlePlanChange(
          selectedPlan === 'monthly' ? 'sixMonths' : 
          selectedPlan === 'sixMonths' ? 'twelveMonths' : 
          selectedPlan
        );
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [selectedPlan]);

  const calculatePrice = (plan) => {
    if (loading || !price?.amount) return { total: '...', perMonth: '...' };
    
    const monthlyPrice = price.amount;
    const discounts = {
      sixMonths: 0.9, // 10% скидка
      twelveMonths: 0.83, // 17% скидка
      monthly: 1
    };

    const months = {
      sixMonths: 6,
      twelveMonths: 12,
      monthly: 1
    };

    const total = monthlyPrice * months[plan] * discounts[plan];
    
        return {
      total: total.toFixed(2),
      perMonth: (total / months[plan]).toFixed(2)
    };
  };

  const handlePlanChange = (plan) => {
    if (plan === selectedPlan) return;
    
    setIsUpdating(true);
    const animationTimeout = setTimeout(() => {
      setSelectedPlan(plan);
      setPriceKey(prev => prev + 1);
      
      const resetTimeout = setTimeout(() => {
        setIsUpdating(false);
      }, 50);

      return () => clearTimeout(resetTimeout);
    }, 300);

    return () => clearTimeout(animationTimeout);
  };

  const handleMenuKeyPress = (e) => {
    if (e.key === 'ArrowUp') {
      setSelectedMenuItem(prev => prev > 0 ? prev - 1 : 6);
    } else if (e.key === 'ArrowDown') {
      setSelectedMenuItem(prev => prev < 6 ? prev + 1 : 0);
    } else if (e.key === 'Enter') {
      handleMenuAction(selectedMenuItem);
    }
  };

  const handleMenuAction = (index) => {
    switch(index) {
      case 0: // Stop node
        setNodeStatus('stopped');
        break;
      
      case 1: // Restart node
        setNodeStatus('restarting');
        setTimeout(() => {
          setNodeStatus('running');
          setLogs(prev => [...prev, 'Node successfully restarted']);
        }, 2000);
        break;
      
      case 2: // Show logs
        setShowLogs(true);
        setLogs(prev => [...prev, 
          'Starting node process...',
          'Initializing blockchain connection...',
          'Syncing with network...',
          'Node is running'
        ]);
        break;
      
      case 3: // Worker management
        const newCount = prompt('Enter number of workers (1-4):', workerCount);
        if (newCount && !isNaN(newCount) && newCount >= 1 && newCount <= 4) {
          setWorkerCount(Number(newCount));
          setLogs(prev => [...prev, `Worker count updated to ${newCount}`]);
        }
        break;
      
      case 4: // Remove node
        if (window.confirm('Are you sure you want to remove the node completely?')) {
          setNodeStatus('stopped');
          setLogs(prev => [...prev, 'Node removed successfully']);
        }
        break;
      
      case 5: // System info
        setSystemInfo({
          os: 'Linux 5.15.0-56-generic',
          cpu: 'AMD Ryzen 9 5950X (32) @ 3.400GHz',
          memory: '64GB DDR4',
          storage: '2TB NVMe SSD',
          network: '1Gbps Ethernet'
        });
        break;
      
      case 6: // Exit
        setShowNodeMenu(false);
        break;
      
      default:
        break;
    }
  };

  useEffect(() => {
    if (showSyncManager) {
      const syncInterval = setInterval(() => {
        setSyncStatus(prev => {
          const newProgress = prev.progress + 0.01;
          if (newProgress >= 1) {
            clearInterval(syncInterval);
            return prev;
          }

          const currentBlock = Math.floor(newProgress * prev.totalBlocks);
          const remainingBlocks = prev.totalBlocks - currentBlock;
          const eta = remainingBlocks > 0 
            ? `${Math.ceil(remainingBlocks / 1200000)} hours ${Math.ceil((remainingBlocks % 1200000) / 20000)} minutes`
            : 'Complete';

          return {
            ...prev,
            progress: newProgress,
            currentBlock,
            eta
          };
        });
      }, 100);

      return () => clearInterval(syncInterval);
    }
  }, [showSyncManager]);

  useEffect(() => {
    if (showAnalytics) {
      const analyticsInterval = setInterval(() => {
        setAnalyticsData(prev => ({
          cpu: {
            usage: Math.min(100, prev.cpu.usage + (Math.random() * 10 - 5)),
            temperature: Math.min(90, prev.cpu.temperature + (Math.random() * 2 - 1)),
            threads: prev.cpu.threads
          },
          memory: {
            used: Math.min(prev.memory.total, prev.memory.used + (Math.random() * 0.5 - 0.25)),
            total: prev.memory.total,
            swap: prev.memory.swap
          },
          network: {
            incoming: Math.max(0, prev.network.incoming + (Math.random() * 20 - 10)),
            outgoing: Math.max(0, prev.network.outgoing + (Math.random() * 15 - 7.5)),
            peers: Math.max(0, prev.network.peers + (Math.floor(Math.random() * 3) - 1))
          },
          storage: {
            used: prev.storage.used + (Math.random() * 0.1),
            total: prev.storage.total,
            readSpeed: Math.max(0, prev.storage.readSpeed + (Math.random() * 50 - 25)),
            writeSpeed: Math.max(0, prev.storage.writeSpeed + (Math.random() * 40 - 20))
          }
        }));
      }, 1000);

      return () => clearInterval(analyticsInterval);
    }
  }, [showAnalytics]);

  const handlePauseSync = () => {
    setSyncStatus(prev => ({
      ...prev,
      isPaused: !prev.isPaused
    }));
  };

  const handleVerifyCheckpoints = () => {
    setLogs(prev => [...prev, 
      'Verifying checkpoint at block 8,245,000...',
      'Checkpoint verified successfully',
      'All blockchain data is valid'
    ]);
  };

  const handleExportData = () => {
    const data = {
      timestamp: new Date().toISOString(),
      metrics: analyticsData
    };
    const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'node-metrics.json';
    a.click();
  };

  const handleConfigureAlerts = () => {
    alert('Alert configuration will be available in the next update');
  };

  useEffect(() => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [messages]);

  const handleSendMessage = (e) => {
    e.preventDefault();
    setShowSubscriptionAlert(true);
  };

  const getAIResponse = (message) => {
    const lowercaseMsg = message.toLowerCase();
    
    if (lowercaseMsg.includes('install') || lowercaseMsg.includes('setup')) {
      return `To install the node:
1. Run installation script: ./xnode install
2. Configure network settings
3. Start the node with: xnode start
Need more detailed instructions?`;
    }
    
    if (lowercaseMsg.includes('error') || lowercaseMsg.includes('issue')) {
      return `I'll help you troubleshoot. Please provide:
1. Error message/logs
2. Node version
3. System specifications
This will help identify the issue faster.`;
    }
    
    if (lowercaseMsg.includes('sync') || lowercaseMsg.includes('synchronization')) {
      return `Current network sync status:
- Latest block: 8,450,000
- Sync speed: ~1.2M blocks/hour
- ETA: 2h 15m remaining
Use 'xnode sync --status' for real-time updates.`;
    }
    
    if (lowercaseMsg.includes('price') || lowercaseMsg.includes('cost')) {
      return `Current subscription plans:
[>] Monthly: $99/month
[>] 6 Months: $89/month (-10%)
[>] Annual: $82/month (-17%)
All plans include 24/7 priority support.`;
    }
    
    if (lowercaseMsg.includes('worker') || lowercaseMsg.includes('performance')) {
      return `Worker management tips:
1. Optimal worker count: 2-4 per CPU core
2. Monitor CPU usage (keep under 80%)
3. Check network bandwidth
Use 'xnode worker --status' for metrics.`;
    }
    
    if (lowercaseMsg.includes('backup') || lowercaseMsg.includes('restore')) {
      return `Backup procedures:
1. Auto backup: Enabled (daily)
2. Location: /var/xnode/backups/
3. Restore command: xnode restore --from=<date>
Latest backup: ${new Date().toLocaleDateString()}`;
    }
    
    if (lowercaseMsg.includes('update') || lowercaseMsg.includes('upgrade')) {
      return `Update available: v2.1.4
Changes:
- Performance improvements
- Security patches
- New monitoring features
Run 'xnode upgrade' to install.`;
    }

    const generalResponses = [
      "I understand. Could you provide more details about your question?",
      "Let me check that for you. One moment please...",
      "I'll help you with that. What specific information do you need?",
      "I'm accessing the documentation for this topic...",
      "Would you like me to show you a step-by-step guide?"
    ];

    return generalResponses[Math.floor(Math.random() * generalResponses.length)];
  };

  const handleFeatureChange = (index) => {
    setIsChanging(true);
    setTimeout(() => {
      setSelectedFeature(index);
      setIsChanging(false);
    }, 150);
  };

  useEffect(() => {
    const startAutoSwitch = () => {
      intervalRef.current = setInterval(() => {
        setIsChanging(true);
        setTimeout(() => {
          setSelectedFeature((prev) => (prev + 1) % features.length);
          setIsChanging(false);
        }, 150);
      }, 5000);
    };

    startAutoSwitch();

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [features.length]);

  const handleMouseEnter = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  const handleMouseLeave = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = setInterval(() => {
      setSelectedFeature((prev) => (prev + 1) % features.length);
    }, 5000);
  };

  const toggleSecurityItem = (item) => {
    const wasActive = item.classList.contains('active');
    document.querySelectorAll('.xnpriv-security-item.active')
      .forEach(el => el.classList.remove('active'));
    if (!wasActive) {
      item.classList.add('active');
    }
  };

  const toggleTroubleItem = (item) => {
    const wasActive = item.classList.contains('active');
    document.querySelectorAll('.xnpriv-trouble-item.active')
      .forEach(el => el.classList.remove('active'));
    if (!wasActive) {
      item.classList.add('active');
    }
  };

  // Проверка аутентификации при загрузке компонента
  useEffect(() => {
    // Здесь можно добавить проверку токена или другую логику аутентификации
    const checkAuth = () => {
      const token = localStorage.getItem('token');
      setIsAuthenticated(!!token);
    };
    
    checkAuth();
  }, []);

  return (
    <>
      <Helmet>
        <title>{t.meta.title}</title>
        <meta name="description" content={t.meta.description} />
        <meta name="keywords" content={t.meta.keywords} />
      </Helmet>

      <div className="private-access-container">
        <LanguageToggle />
        
        <div className="private-header">
          <div className="private-header-ascii">
            ╔══════════════════╗
            ║  SECURE ACCESS   ║
            ║    PROTOCOL      ║
            ╚══════════════════╝
          </div>
          <h1 className="typing-text">Secure Access Protocol_</h1>
          <p className="header-description">
            Welcome to the encrypted realm of <strong>xNod3</strong>. Our private access protocol 
            provides <strong>enhanced security</strong> features, <strong>priority support</strong>, 
            and exclusive tools for <strong>blockchain infrastructure</strong> management. Join the 
            elite network of node operators with privileged access to <strong>advanced capabilities</strong>.
          </p>
        </div>

        <section className="xnprivate-pricing">
          <div className="xnprivate-sidebar">
            <h2 className="xnprivate-title">Select Access Level</h2>
            <div className="xnprivate-plans">
            <button 
                className={`xnprivate-plan-option ${selectedPlan === 'monthly' ? 'active' : ''}`}
                onClick={() => handlePlanChange('monthly')}
            >
                <span className="xnprivate-plan-icon">[M]</span>
                Monthly Access
            </button>
            
            <button 
                className={`xnprivate-plan-option ${selectedPlan === 'sixMonths' ? 'active' : ''}`}
                onClick={() => handlePlanChange('sixMonths')}
              >
                <span className="xnprivate-plan-icon">[6M]</span>
                6 Months Access
                <span className="xnprivate-savings">-10%</span>
            </button>
            
            <button 
                className={`xnprivate-plan-option ${selectedPlan === 'twelveMonths' ? 'active' : ''}`}
                onClick={() => handlePlanChange('twelveMonths')}
              >
                <span className="xnprivate-plan-icon">[1Y]</span>
                Annual Access
                <span className="xnprivate-savings">-17%</span>
            </button>
          </div>
          </div>

          <div className="xnprivate-content">
            <div className="xnprivate-badge">
              Membership Access
            </div>
            
            <div className="xnprivate-price-wrapper">
              <div 
                key={priceKey}
                className={`xnprivate-price ${isUpdating ? 'exiting' : ''}`}
              >
                ${calculatePrice(selectedPlan).total}
              </div>
            </div>

            <ul className="xnprivate-benefits">
              {t.pricing.benefits.map((benefit, index) => (
                <li key={index} className="xnprivate-benefit">
                  <span className="xnprivate-plan-icon">[>]</span>
                  {benefit}
                </li>
              ))}
            </ul>

            <div className="xnprivate-actions">
              <Link 
                to="/dashboard" 
                className="xnpriv-join-btn"
                onClick={(e) => {
                  e.preventDefault();
                  if (!isAuthenticated) {
                    setShowAuthModal(true);
                  } else {
                    navigate('/dashboard');
                  }
                }}
              >
                [ JOIN INNER CIRCLE ]
              </Link>

              <div className="xnprivate-secure">
                <span className="xnprivate-secure-icon">[🔒]</span>
                Secure Payment • Cancel Anytime
              </div>
            </div>
          </div>
        </section>

        <section className="xnprivate-scripts">
          <h2 className="xnprivate-scripts-title">Custom Node Scripts</h2>
          
          <div className="xnprivate-scripts-grid">
            <div className="xnprivate-script-card">
              <div className="xnprivate-script-header">
                <span className="xnprivate-script-icon">[⚡]</span>
                <h3 className="xnprivate-script-name">One-Click Node Manager</h3>
              </div>
              <p className="xnprivate-script-description">
                Advanced CLI tool for instant node deployment and management. Install, configure and 
                control your node with a single command through an intuitive terminal interface.
              </p>
              <div className="xnprivate-script-footer">
                <div className="xnprivate-script-tech">
                  <span className="xnprivate-tech-tag">Python</span>
                  <span className="xnprivate-tech-tag">Shell</span>
                </div>
                <button 
                  className="xnprivate-script-preview"
                  onClick={() => setShowNodeMenu(true)}
                >
                  Launch Manager [→]
                </button>
            </div>
            </div>
            
            <div className="xnprivate-script-card">
              <div className="xnprivate-script-header">
                <span className="xnprivate-script-icon">[🔒]</span>
                <h3 className="xnprivate-script-name">Secure Sync Manager</h3>
              </div>
              <p className="xnprivate-script-description">
                Enhanced blockchain synchronization with advanced security measures and 
                optimized performance for faster block processing. Real-time progress monitoring 
                and automatic checkpoint verification.
              </p>
              <div className="xnprivate-script-footer">
                <div className="xnprivate-script-tech">
                  <span className="xnprivate-tech-tag">Rust</span>
                  <span className="xnprivate-tech-tag">Go</span>
                </div>
                <button 
                  className="xnprivate-script-preview"
                  onClick={() => setShowSyncManager(true)}
                >
                  Launch Sync [→]
                </button>
              </div>
            </div>

            <div className="xnprivate-script-card">
              <div className="xnprivate-script-header">
                <span className="xnprivate-script-icon">[📊]</span>
                <h3 className="xnprivate-script-name">Performance Analytics</h3>
              </div>
              <p className="xnprivate-script-description">
                Real-time monitoring and analytics suite with customizable alerts, 
                resource usage tracking, and detailed performance metrics visualization.
                Includes predictive maintenance.
              </p>
              <div className="xnprivate-script-footer">
                <div className="xnprivate-script-tech">
                  <span className="xnprivate-tech-tag">Node.js</span>
                  <span className="xnprivate-tech-tag">GraphQL</span>
                </div>
                <button 
                  className="xnprivate-script-preview"
                  onClick={() => setShowAnalytics(true)}
                >
                  View Metrics [→]
                </button>
              </div>
            </div>
          </div>
        </section>

        <section className="xnprivate-guides">
          <div className="xnprivate-guides-content">
            <div className="xnprivate-guides-main">
              <h2 className="xnprivate-guides-title">Documentation & Guides</h2>
              <p className="guides-info-description">
                Access our comprehensive collection of guides, tutorials, and documentation. 
                From basic node setup to advanced optimization techniques, our knowledge base 
                covers everything you need to succeed in blockchain infrastructure management.
              </p>
              
              <ul className="guides-features-list" 
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
              >
                {features.map((feature, index) => (
                  <li 
                    key={feature.id}
                    className={`guide-feature-item ${selectedFeature === index ? 'active' : ''}`}
                    onClick={() => handleFeatureChange(index)}
                  >
                    <span className="feature-icon">{feature.icon}</span>
                    <span className="feature-title">{feature.title}</span>
                  </li>
                ))}
              </ul>
            </div>

            <div className={`xnprivate-guides-window ${isChanging ? 'changing' : ''}`} data-category={features[selectedFeature].id}>
              <div className="xnprivate-guides-header">
                <span className="guides-icon">{features[selectedFeature].icon}</span>
                {features[selectedFeature].id.toUpperCase()}_DOCS v2.1.4
                <div className="guides-status">
                  <span className="status-dot"></span>
                  VERIFIED
                </div>
              </div>
              
              <div className="xnprivate-guides-preview">
                {selectedFeature === 0 && (
                  <div className="setup-guide-preview">
                    <div className="terminal-window">
                      <div className="terminal-header">
                        <div className="terminal-title">
                          <span className="terminal-title-main">XNOD3</span>
                          <span className="terminal-title-sub">INSTALLATION</span>
                        </div>
                        <div className="terminal-status">
                          <span className="status-indicator active"></span>
                          <span className="status-text">CONNECTED</span>
                        </div>
                      </div>
                      
                      <div className="terminal-content">
                        <div className="command-line">
                          <span className="prompt">root@xnode:~$</span>
                          <span className="command">./install_node.sh</span>
                          <span className="cursor"></span>
                        </div>

                        <div className="progress-line">
                          <span className="progress-label">Installing dependencies...</span>
                          <div className="progress-bar">
                            <div className="progress-bar-fill" style={{width: '100%'}}></div>
                          </div>
                          <span className="progress-percentage">100%</span>
                        </div>

                        <div className="output-line success">
                          <span className="output-icon">✓</span>
                          <span className="output-text">Dependencies installed successfully</span>
                        </div>

                        <div className="output-line success">
                          <span className="output-icon">✓</span>
                          <span className="output-text">Configuration verified</span>
                        </div>

                        <div className="output-line success">
                          <span className="output-icon">✓</span>
                          <span className="output-text">Network connection established</span>
                        </div>

                        <div className="command-line">
                          <span className="prompt">root@xnode:~$</span>
                          <span className="command">node status</span>
                        </div>

                        <div className="output-line success">
                          <span className="output-icon">⬤</span>
                          <span className="output-text">Node is running</span>
                          <span className="output-details">PID: 1234 | Port: 30303</span>
                        </div>

                        <div className="output-line info">
                          <span className="output-icon">ℹ</span>
                          <span className="output-text">Type 'node help' for available commands</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {selectedFeature === 1 && (
                  <div className="xnpriv-performance-block">
                    <div className="xnpriv-performance-header">
                      <span className="xnpriv-performance-icon">[⚡]</span>
                      <h3 className="xnpriv-performance-title">Performance Optimization</h3>
                    </div>

                    <div className="xnpriv-metrics-list">
                      <div className="xnpriv-metric-item">
                        <span className="xnpriv-metric-label">Node Uptime</span>
                        <span className="xnpriv-metric-value">99.9%</span>
                      </div>

                      <div className="xnpriv-metric-item">
                        <span className="xnpriv-metric-label">Average Response Time</span>
                        <span className="xnpriv-metric-value">45ms</span>
                      </div>

                      <div className="xnpriv-metric-item">
                        <span className="xnpriv-metric-label">Resource Usage Reduction</span>
                        <span className="xnpriv-metric-value">-30%</span>
                      </div>

                      <div className="xnpriv-metric-item">
                        <span className="xnpriv-metric-label">Parallel Processing</span>
                        <span className="xnpriv-metric-value">Enabled</span>
                      </div>

                      <div className="xnpriv-metric-item">
                        <span className="xnpriv-metric-label">Workers per CPU</span>
                        <span className="xnpriv-metric-value">x4</span>
                      </div>
                    </div>

                    <div className="xnpriv-performance-footer">
                      <a href="#" className="xnpriv-docs-link">ACCESS DOCUMENTATION</a>
                    </div>
                  </div>
                )}

                {selectedFeature === 2 && (
                  <div className="xnpriv-security-block">
                    <div className="xnpriv-security-header">
                      <h3 className="xnpriv-security-title">Security Guidelines</h3>
                      <span className="xnpriv-security-version">v1.2.8</span>
                    </div>

                    <div className="xnpriv-security-list">
                      <div className="xnpriv-security-item" onClick={(e) => toggleSecurityItem(e.currentTarget)}>
                        <span className="xnpriv-security-level critical">CRITICAL</span>
                        <span>Private Key Management</span>
                        <div className="xnpriv-security-details">
                          • Never store private keys in plain text
                          • Use hardware security modules when possible
                          • Implement key rotation policies
                          <div className="xnpriv-security-status">Last updated: 1 day ago</div>
                        </div>
                      </div>

                      <div className="xnpriv-security-item" onClick={(e) => toggleSecurityItem(e.currentTarget)}>
                        <span className="xnpriv-security-level high">HIGH</span>
                        <span>Access Control</span>
                        <div className="xnpriv-security-details">
                          • Implement role-based access control
                          • Regular audit of access patterns
                          • Multi-factor authentication required
                          <div className="xnpriv-security-status">Last updated: 3 days ago</div>
                        </div>
                      </div>

                      <div className="xnpriv-security-item" onClick={(e) => toggleSecurityItem(e.currentTarget)}>
                        <span className="xnpriv-security-level medium">MEDIUM</span>
                        <span>Network Security</span>
                        <div className="xnpriv-security-details">
                          • Use encrypted connections only
                          • Regular security scans
                          • DDoS protection enabled
                          <div className="xnpriv-security-status">Last updated: 5 days ago</div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {selectedFeature === 3 && (
                  <div className="xnpriv-trouble-block">
                    <div className="xnpriv-trouble-header">
                      <h3 className="xnpriv-trouble-title">Troubleshooting Manuals</h3>
                    </div>

                    <div className="xnpriv-trouble-list">
                      <div className="xnpriv-trouble-item" onClick={(e) => toggleTroubleItem(e.currentTarget)}>
                        <div className="xnpriv-trouble-item-header">
                          <span className="xnpriv-trouble-code">ERR_NODE_SYNC</span>
                          <span className="xnpriv-trouble-status resolved">RESOLVED</span>
                        </div>
                        <div>Node Synchronization Issues</div>
                        <div className="xnpriv-trouble-solution">
                          <ul className="xnpriv-trouble-steps">
                            <li className="xnpriv-trouble-step">Check network connectivity</li>
                            <li className="xnpriv-trouble-step">Verify peer connections</li>
                            <li className="xnpriv-trouble-step">Run diagnostic command:</li>
                          </ul>
                          <div className="xnpriv-trouble-command">
                            $ ./node-diagnostic.sh --check-sync
                          </div>
                        </div>
                      </div>

                      <div className="xnpriv-trouble-item" onClick={(e) => toggleTroubleItem(e.currentTarget)}>
                        <div className="xnpriv-trouble-item-header">
                          <span className="xnpriv-trouble-code">ERR_MEMORY_LEAK</span>
                          <span className="xnpriv-trouble-status pending">PENDING</span>
                        </div>
                        <div>Memory Optimization Required</div>
                        <div className="xnpriv-trouble-solution">
                          <ul className="xnpriv-trouble-steps">
                            <li className="xnpriv-trouble-step">Monitor memory usage</li>
                            <li className="xnpriv-trouble-step">Clear cache if necessary</li>
                            <li className="xnpriv-trouble-step">Execute cleanup:</li>
                          </ul>
                          <div className="xnpriv-trouble-command">
                            $ ./memory-cleanup.sh --force
                          </div>
                        </div>
                      </div>

                      <div className="xnpriv-trouble-item" onClick={(e) => toggleTroubleItem(e.currentTarget)}>
                        <div className="xnpriv-trouble-item-header">
                          <span className="xnpriv-trouble-code">ERR_CONNECTION</span>
                          <span className="xnpriv-trouble-status resolved">RESOLVED</span>
                        </div>
                        <div>Connection Timeout Issues</div>
                        <div className="xnpriv-trouble-solution">
                          <ul className="xnpriv-trouble-steps">
                            <li className="xnpriv-trouble-step">Check firewall settings</li>
                            <li className="xnpriv-trouble-step">Verify port configuration</li>
                            <li className="xnpriv-trouble-step">Test connection:</li>
                          </ul>
                          <div className="xnpriv-trouble-command">
                            $ netstat -tulpn | grep node
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="guides-action-panel">
                  <Link to="/guides" className="guides-action-btn">
                    [ACCESS FULL DOCUMENTATION →]
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="xnprivate-community">
          <div className="xnprivate-community-header">
            <div className="xnprivate-community-title">
              <span className="title-icon">💬</span>
              xNod3 Community Chat
              <span className="online-indicator">● 42 online</span>
            </div>
            <div className="xnprivate-header-description">
              Receive personalized support for node setup and troubleshooting directly from our experts
              <Link to="/dashboard" className="xnprivate-action-button">
                [Upgrade to Pro →]
              </Link>
            </div>
          </div>

          <div className="xnprivate-chat-feed" ref={chatRef}>
            {messages.map((msg) => (
              <div 
                key={msg.id}
                className={`chat-message ${msg.isAdmin ? 'admin-message' : ''} ${
                  msg.type === 'system' ? 'system-message' : ''
                }`}
              >
                {msg.type === 'system' ? (
                  <div className="system-content">{msg.content}</div>
                ) : (
                  <>
                    <div className="message-avatar" data-author={msg.author}>
                      {msg.avatar}
                    </div>
                    <div className="message-content">
                      <div className="message-header">
                        <span className={`author-name ${msg.isAdmin ? 'admin-name' : ''}`}>
                          {msg.author}
                        </span>
                        <span className="message-time">{msg.timestamp}</span>
                  </div>
                      <div className="message-text">{msg.content}</div>
                    </div>
                  </>
                  )}
                </div>
              ))}
            </div>

          <form className="xnprivate-chat-input" onSubmit={handleSendMessage}>
            <div className="input-avatar">👤</div>
            <input
              type="text"
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              placeholder="Join the conversation..."
              className="chat-input-field"
            />
            <button type="submit" className="send-button">
              Send Message
            </button>
          </form>
          </section>

        <section className="xnprivate-faq">
          <div className="xnprivate-faq-header">
            <span className="faq-icon">❯_</span>
            <span className="faq-title">FAQ Database</span>
            <div className="faq-header-line"></div>
          </div>

          <div className="xnprivate-faq-grid">
            {t.faqData.map((item) => (
              <div 
                key={item.id}
                className={`faq-item ${expandedFaq === item.id ? 'expanded' : ''}`}
                onClick={() => setExpandedFaq(expandedFaq === item.id ? null : item.id)}
              >
                <div className="faq-question">
                  <div className="faq-question-header">
                    <span className="faq-marker">[FAQ_{String(item.id).padStart(2, '0')}]</span>
                    <span className="faq-status">{expandedFaq === item.id ? 'ACTIVE' : 'IDLE'}</span>
                  </div>
                  <div className="faq-question-content">
                    {item.question}
                  </div>
                  <span className="faq-toggle">
                    {expandedFaq === item.id ? '[-]' : '[+]'}
                  </span>
                </div>
                <div className="faq-answer">
                  <div className="faq-answer-content">
                    <span className="answer-prefix">[RESPONSE]</span>
                    {item.answer.map((line, i) => (
                      <div key={i} className="answer-line">
                      {line.includes('subscription') || line.includes('подписк') ? (
                        <>
                          {line}{' '}
                          <Link to="/dashboard" className="xnprivate-action-button pulsing">
                            [Get Full Access to Private Knowledge Base →]
                          </Link>
                        </>
                      ) : (
                        line
                      )}
                      </div>
                  ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
          
          <div className="faq-footer">
            <Link to="/dashboard" className="xnprivate-action-button pulsing">
              [Get Full Access to Private Knowledge Base →]
            </Link>
          </div>
        </section>

        {showNodeMenu && (
          <div className="xnprivate-modal-overlay" onClick={() => setShowNodeMenu(false)}>
            <section className="xnprivate-node-menu" onClick={e => e.stopPropagation()}>
              <div className="xnprivate-menu-window" onKeyDown={handleMenuKeyPress} tabIndex={0}>
                <div className="xnprivate-menu-header">
                  OpenLedger Node v1.0.0
                </div>
                
                <div className="xnprivate-menu-status">
                  <span className="xnprivate-status-icon" data-status={nodeStatus}>
                    {nodeStatus === 'running' ? '✓' : nodeStatus === 'stopped' ? '⬤' : '↻'}
                  </span>
                  {nodeStatus === 'running' ? 'Node is running' : 
                   nodeStatus === 'stopped' ? 'Node is stopped' : 
                   'Restarting...'}
                </div>

                {showLogs && (
                  <div className="xnprivate-logs">
                    {logs.map((log, index) => (
                      <div key={index}>[{new Date().toLocaleTimeString()}] {log}</div>
                    ))}
                  </div>
                )}

                {systemInfo && (
                  <div className="xnprivate-system-info">
                    <div className="xnprivate-system-info-item">
                      <span>OS:</span>
                      <span>{systemInfo.os}</span>
                    </div>
                    <div className="xnprivate-system-info-item">
                      <span>CPU:</span>
                      <span>{systemInfo.cpu}</span>
                    </div>
                    <div className="xnprivate-system-info-item">
                      <span>Memory:</span>
                      <span>{systemInfo.memory}</span>
                    </div>
                    <div className="xnprivate-system-info-item">
                      <span>Storage:</span>
                      <span>{systemInfo.storage}</span>
                    </div>
                    <div className="xnprivate-system-info-item">
                      <span>Network:</span>
                      <span>{systemInfo.network}</span>
                    </div>
                  </div>
                )}

                <ul className="xnprivate-menu-list">
                  {menuItems.map((item, index) => (
                    <li 
                      key={index}
                      className={`xnprivate-menu-item ${selectedMenuItem === index ? 'active' : ''} ${
                        nodeStatus === 'restarting' ? 'disabled' : ''
                      }`}
                      onClick={() => nodeStatus !== 'restarting' && handleMenuAction(index)}
                    >
                      <span className="xnprivate-menu-item-icon">{item.icon}</span>
                      {item.label}
                    </li>
                  ))}
                </ul>

                <div className="xnprivate-menu-controls">
                  <div className="xnprivate-menu-nav">
                    <button 
                      className="xnprivate-menu-nav-btn"
                      onClick={() => setSelectedMenuItem(prev => prev > 0 ? prev - 1 : 6)}
                    >
                      ←
                    </button>
                    <span className="xnprivate-menu-counter">{selectedMenuItem}</span>
                    <button 
                      className="xnprivate-menu-nav-btn"
                      onClick={() => setSelectedMenuItem(prev => prev < 6 ? prev + 1 : 0)}
                    >
                      →
                    </button>
                  </div>
                  <span className="xnprivate-menu-cancel" onClick={() => setShowNodeMenu(false)}>
                    &lt;Cancel&gt;
                  </span>
                </div>
              </div>
            </section>
          </div>
        )}

        {showSyncManager && (
          <div className="xnprivate-modal-overlay" onClick={() => setShowSyncManager(false)}>
            <div className="xnprivate-sync-window" onClick={e => e.stopPropagation()}>
              <div className="xnprivate-sync-header">
                Secure Sync Manager v2.1.4
                <div className="xnprivate-sync-status">
                  Syncing... {Math.round(syncStatus.progress * 100)}%
                </div>
              </div>
              
              <div className="xnprivate-sync-progress">
                <div 
                  className="xnprivate-sync-bar" 
                  style={{width: `${syncStatus.progress * 100}%`}}
                />
              </div>

              <div className="xnprivate-sync-details">
                <div className="xnprivate-sync-item">
                  <span>Current Block:</span>
                  <span>{syncStatus.currentBlock.toLocaleString()}</span>
                </div>
                <div className="xnprivate-sync-item">
                  <span>Total Blocks:</span>
                  <span>{syncStatus.totalBlocks.toLocaleString()}</span>
                </div>
                <div className="xnprivate-sync-item">
                  <span>Speed:</span>
                  <span>{syncStatus.speed}</span>
                </div>
                <div className="xnprivate-sync-item">
                  <span>ETA:</span>
                  <span>{syncStatus.eta}</span>
                </div>
              </div>

              <div className="xnprivate-sync-controls">
                <button className="xnprivate-sync-btn" onClick={handlePauseSync}>
                  {syncStatus.isPaused ? 'Resume Sync' : 'Pause Sync'}
                </button>
                <button className="xnprivate-sync-btn" onClick={handleVerifyCheckpoints}>
                  Verify Checkpoints
                </button>
                <button 
                  className="xnprivate-sync-btn cancel"
                  onClick={() => setShowSyncManager(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}

        {showAnalytics && (
          <div className="xnprivate-modal-overlay" onClick={() => setShowAnalytics(false)}>
            <div className="xnprivate-analytics-window" onClick={e => e.stopPropagation()}>
              <div className="xnprivate-analytics-header">
                Performance Analytics
                <span className="xnprivate-analytics-time">
                  {new Date().toLocaleTimeString()}
                </span>
              </div>

              <div className="xnprivate-analytics-grid">
                <div className="xnprivate-metric-card">
                  <h4>CPU Usage</h4>
                  <div className="xnprivate-metric-value">
                    {analyticsData.cpu.usage}%
                  </div>
                  <div className="xnprivate-metric-details">
                    <div>Temperature: {analyticsData.cpu.temperature}°C</div>
                    <div>Active Threads: {analyticsData.cpu.threads}</div>
                  </div>
                </div>

                <div className="xnprivate-metric-card">
                  <h4>Memory</h4>
                  <div className="xnprivate-metric-value">
                    {analyticsData.memory.used}GB / {analyticsData.memory.total}GB
                  </div>
                  <div className="xnprivate-metric-details">
                    <div>Usage: {(analyticsData.memory.used / analyticsData.memory.total * 100).toFixed(1)}%</div>
                    <div>Swap: {analyticsData.memory.swap}GB</div>
                  </div>
                </div>

                <div className="xnprivate-metric-card">
                  <h4>Network</h4>
                  <div className="xnprivate-metric-value">
                    ↓{analyticsData.network.incoming}MB/s ↑{analyticsData.network.outgoing}MB/s
                  </div>
                  <div className="xnprivate-metric-details">
                    <div>Active Peers: {analyticsData.network.peers}</div>
                  </div>
                </div>

                <div className="xnprivate-metric-card">
                  <h4>Storage</h4>
                  <div className="xnprivate-metric-value">
                    {analyticsData.storage.used}GB / {analyticsData.storage.total}TB
                  </div>
                  <div className="xnprivate-metric-details">
                    <div>Read: {analyticsData.storage.readSpeed}MB/s</div>
                    <div>Write: {analyticsData.storage.writeSpeed}MB/s</div>
                  </div>
                </div>
              </div>

              <div className="xnprivate-analytics-controls">
                <button className="xnprivate-analytics-btn" onClick={handleExportData}>
                  Export Data
                </button>
                <button className="xnprivate-analytics-btn" onClick={handleConfigureAlerts}>
                  Configure Alerts
                </button>
                <button 
                  className="xnprivate-analytics-btn cancel"
                  onClick={() => setShowAnalytics(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}

        {showSubscriptionAlert && (
          <div className="subscription-alert-overlay" onClick={() => setShowSubscriptionAlert(false)}>
            <div className="subscription-alert" onClick={e => e.stopPropagation()}>
              <button 
                className="alert-close-btn" 
                onClick={() => setShowSubscriptionAlert(false)}
              >
                [×]
              </button>
              <div className="alert-title">[ACCESS DENIED]</div>
              <div className="alert-message">
                This feature requires an active subscription. Upgrade your account to access the community chat and other premium features.
              </div>
              <Link to="/dashboard" className="alert-button">
                [Upgrade Now →]
              </Link>
            </div>
          </div>
        )}

        {/* Модальное окно авторизации */}
        {showAuthModal && (
          <div className="auth-modal">
            {/* Содержимое модального окна авторизации */}
          </div>
        )}
      </div>
    </>
  );
}

export default PrivateAccess; 