// src/pages/Contact.js
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import '../styles/ContactRetro.css';
import BackgroundMatrix from '../components/BackgroundMatrix';
import axios from 'axios';

function Contact() {
  const [formData, setFormData] = useState({
    nickname: '',
    email: '',
    telegram: '',
    message: ''
  });
  const [status, setStatus] = useState({ type: '', message: '' });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showNotification, setShowNotification] = useState(false);

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleFocus = (field) => {
    setErrors(prev => ({ ...prev, [field]: '' }));
  };

  const handleBlur = (field) => {
    if (field === 'email' && formData.email) {
      if (!validateEmail(formData.email)) {
        setErrors(prev => ({ ...prev, email: 'Invalid email format' }));
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrors({});
    setShowNotification(false);

    const newErrors = {};
    if (!formData.nickname) newErrors.nickname = 'Name/Nickname is required';
    if (!formData.email) newErrors.email = 'Email is required';
    else if (!validateEmail(formData.email)) newErrors.email = 'Invalid email format';
    if (!formData.telegram) newErrors.telegram = 'Telegram username is required';
    if (!formData.message) newErrors.message = 'Message is required';

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setIsSubmitting(false);
      setStatus({ type: 'error', message: 'Please fix the errors above.' });
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/contact/send`,
        formData
      );
      
      if (response.data.success) {
        setStatus({ 
          type: 'confirmed', 
          message: 'Message sent successfully! We will contact you shortly.'
        });
        setFormData({ nickname: '', email: '', telegram: '', message: '' });
        setShowNotification(true);
      } else {
        setStatus({ 
          type: 'error', 
          message: 'Error sending message. Please try again.'
        });
      }
    } catch (error) {
      setStatus({ 
        type: 'error', 
        message: 'Network error. Please check your connection and try again.'
      });
    }
    
    setIsSubmitting(false);
    setTimeout(() => {
      setStatus({ type: '', message: '' });
      setShowNotification(false);
    }, 5000);
  };

  return (
    <>
      <Helmet>
        <title>Contact Us - xNod3 - Get in Touch</title>
        <meta name="description" content="Contact xNod3 for information on blockchain node deployment, enterprise solutions, or private access membership. Connect with our team today." />
        <meta name="keywords" content="contact xNod3, blockchain support, node deployment inquiry, private access, enterprise solutions" />
        <link rel="canonical" href="https://xnod3.com/contact" />
        
        {/* Open Graph */}
        <meta property="og:title" content="Contact Us - xNod3 - Get in Touch" />
        <meta property="og:description" content="Connect with xNod3 for inquiries on our exclusive blockchain solutions and support services." />
        <meta property="og:image" content="/images/contact.png" />
        <meta property="og:url" content="https://xnod3.com/contact" />
        <meta property="og:type" content="website" />
        
        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Contact Us - xNod3 - Get in Touch" />
        <meta name="twitter:description" content="Connect with xNod3 for inquiries on our exclusive blockchain solutions and support services." />
        <meta name="twitter:image" content="/images/contact.png" />
      </Helmet>

      <div className="xncontact-container">
        <BackgroundMatrix />
        
        {showNotification && (
          <div className={`xncontact-notification ${status.type}`}>
            <div className="xncontact-notification-icon">
              {status.type === 'confirmed' ? '✓' : '!'}
            </div>
            <div className="xncontact-notification-content">
              <h3>{status.type === 'confirmed' ? 'Confirmed!' : 'Error'}</h3>
              <p>{status.message}</p>
            </div>
            <button 
              className="xncontact-notification-close"
              onClick={() => setShowNotification(false)}
            >
              ×
            </button>
          </div>
        )}

        <h1 className="xncontact-title">Get in Touch <span className="cursor">_</span></h1>
        <p className="xncontact-description">
          Connect with the xNod3 team for inquiries, support, or partnership opportunities.
        </p>

        <div className="xncontact-methods">
          <div className="xncontact-card">
            <h2 className="xncontact-method-title">
              <span className="xncontact-method-icon">[✉]</span> Email
            </h2>
            <a href="mailto:support@xnod3.com" className="xncontact-link">
              › support@xnod3.com
            </a>
          </div>

          <div className="xncontact-card">
            <h2 className="xncontact-method-title">
              <span className="xncontact-method-icon">[⚡]</span> Telegram
            </h2>
            <div className="xncontact-telegram-links">
              <a href="https://t.me/grisha_nodes" className="xncontact-link">
                › Main Support: @grisha_nodes
              </a>
              <a href="https://t.me/xNod3" className="xncontact-link">
                › Public Channel: @xNod3
              </a>
            </div>
          </div>
        </div>

        <div className="xncontact-form-container">
          <div className="xncontact-form-header">
            <h2 className="xncontact-form-title">Send Message</h2>
            <div className="xncontact-form-status">
              <span className="xncontact-status-dot"></span>
              {isSubmitting ? 'SENDING...' : 'SECURE CONNECTION'}
            </div>
          </div>

          <form onSubmit={handleSubmit} className="xncontact-form">
            <div className="xncontact-input-group">
              <label className="xncontact-input-label">› Name / Nickname:</label>
              <input
                type="text"
                name="nickname"
                value={formData.nickname}
                onChange={(e) => setFormData({...formData, nickname: e.target.value})}
                onFocus={() => handleFocus('nickname')}
                onBlur={() => handleBlur('nickname')}
                className={`xncontact-input ${errors.nickname ? 'error' : ''}`}
                placeholder="_"
              />
              {errors.nickname && <span className="xncontact-error">{errors.nickname}</span>}
            </div>
            
            <div className="xncontact-input-group">
              <label className="xncontact-input-label">› Telegram Username:</label>
              <input
                type="text"
                name="telegram"
                value={formData.telegram}
                onChange={(e) => setFormData({...formData, telegram: e.target.value})}
                onFocus={() => handleFocus('telegram')}
                onBlur={() => handleBlur('telegram')}
                className={`xncontact-input ${errors.telegram ? 'error' : ''}`}
                placeholder="@username"
              />
              {errors.telegram && <span className="xncontact-error">{errors.telegram}</span>}
            </div>
            
            <div className="xncontact-input-group">
              <label className="xncontact-input-label">› Email Address:</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={(e) => setFormData({...formData, email: e.target.value})}
                onFocus={() => handleFocus('email')}
                onBlur={() => handleBlur('email')}
                className={`xncontact-input ${errors.email ? 'error' : ''}`}
                placeholder="_"
              />
              {errors.email && <span className="xncontact-error">{errors.email}</span>}
            </div>
            
            <div className="xncontact-input-group">
              <label className="xncontact-input-label">› Message:</label>
              <textarea
                name="message"
                value={formData.message}
                onChange={(e) => setFormData({...formData, message: e.target.value})}
                onFocus={() => handleFocus('message')}
                onBlur={() => handleBlur('message')}
                className={`xncontact-input xncontact-textarea ${errors.message ? 'error' : ''}`}
                placeholder="_"
                rows="4"
              ></textarea>
              {errors.message && <span className="xncontact-error">{errors.message}</span>}
            </div>
            
            <button 
              type="submit" 
              className={`xncontact-submit-btn ${isSubmitting ? 'submitting' : ''}`} 
              disabled={isSubmitting}
            >
              <span className="xncontact-btn-content">
                {isSubmitting ? (
                  <>
                    <span className="xncontact-loader"></span>
                    Sending...
                  </>
                ) : (
                  <>
                    Send Message <span className="cursor">_</span>
                  </>
                )}
              </span>
            </button>

            {status.message && (
              <div className={`xncontact-status-message ${status.type}`}>
                {status.message}
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
}

export default Contact;
